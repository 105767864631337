<template>
  <CommonsModalsModalBody height="auto" :svg="svg">
    <template #title> Ajouter un exercice </template>

    <template #help
      >Ajoutez des exercices supplémentaires si nécessaire. Il n'est pas possible d'avoir plusieurs
      exercices comprenant la même période.</template
    >

    <template #content>
      <div class="modal-content">
        <div class="form">
          <div class="form-row">
            <div class="col-2">
              <uds-input-calendar
                :date="form.startDate"
                :error="errors.startDate"
                :max-date="form.endDate"
                label="Date de début"
                is-required
                @change-date="form.startDate = $event"
              />
            </div>
            <div class="col-2">
              <uds-input-calendar
                :date="form.endDate"
                :error="errors.endDate"
                :min-date="form.startDate"
                label="Date de fin"
                is-required
                @change-date="form.endDate = $event"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-max">
              <uds-input-checkbox
                label="Définir comme exercice par défaut."
                :is-checked="form.isDefault"
                @input="form.isDefault = !form.isDefault"
              />
            </div>
          </div>
        </div>

        <uds-banner v-if="defaultExercice">
          <template #content>
            <div>
              <uds-icon icon-name="info" :color="getPrimaryColors().primary500" />
            </div>
            <div>
              <p class="uds-paragraph">
                Votre exercice par défaut actuel est :
                <strong
                  >{{ dayjs(defaultExercice.startDate).format("DD/MM/YYYY") }} -
                  {{ dayjs(defaultExercice.endDate).format("DD/MM/YYYY") }}</strong
                >
                <br >Pour gérer vos exercices, rendez-vous dans
                <nuxt-link to="/settings/informations" @click="emit('cancel')"
                  >Paramètres > Informations</nuxt-link
                >.
              </p>
            </div>
          </template>
        </uds-banner>
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <uds-main-button size="small" :disabled="!isFormValid" :loading="isLoading" @click="save()"
        >Ajouter
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { IExercice } from "@silexpert/core";

const { isComptalib } = useBrandsComposable();
const emit = defineEmits<{
  (e: "close", value: { startDate: string; endDate: string }): void;
  (e: "cancel"): void;
}>();

const societyStore = useSocietyStore();

const dayjs = useDayjs();
const exerciceStore = useExerciceStore();

const svg = isComptalib() ? "ComptalibManageFiscalPeriodSvg" : "ManageFiscalPeriodSvg";

const isLoading = ref(false);
const form = ref({
  startDate: null,
  endDate: null,
  isDefault: false,
});

const errors = computed(() => ({
  startDate: isDefined(form.value.startDate) ? null : "Veuillez sélectionner une date",
  endDate: isDefined(form.value.endDate) ? null : "Veuillez sélectionner une date",
}));

const defaultExercice = computed<MaybeNotDefined<IExercice>>(() => {
  return exerciceStore.exercices.find((exercice) => exercice.isDefault);
});

const isFormValid = computed(() => {
  return Object.values(errors.value).every((error) => !isDefined(error));
});

async function save() {
  try {
    if (!form.value.startDate || !form.value.endDate) return;

    isLoading.value = true;
    await $silex().exercice.create({
      idSociety: societyStore.society!.id!,
      startDate: form.value.startDate,
      endDate: form.value.endDate,
      isDefault: form.value.isDefault,
    });

    await exerciceStore.fetchExercices(societyStore.society!.id!);

    $notifier().open({ type: "success", content: "L'exercice a bien été créé." });
    emit("close", { startDate: form.value.startDate, endDate: form.value.endDate });
  } catch (error) {
    $notifier().open({ content: apiErrorToString(error) });
  } finally {
    isLoading.value = false;
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  :deep(.banner-container) {
    .uds-paragraph {
      margin-top: 0px;
      margin-bottom: 0px;
      padding-left: $uds-spacing-2;
    }
    a {
      color: $uds-primary-500;
    }
  }
}
</style>
